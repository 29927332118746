import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'codenteam-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
})
export class PromptDialogComponent {
  message;
  canCancel = true;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.name;
    if (data.canCancel === false) {
      this.canCancel = false;
    }
  }
}
