<div class="!bg-slate-800">
  <h1
    mat-dialog-title
    class="!font-boldX"
  >
    Alert
  </h1>
  <mat-dialog-content data-test="expired-message">
    {{ data.name }}
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-button
      mat-dialog-close
      data-test="cancel-button"
      *ngIf="canCancel"
    >
      Cancel
    </button>
    <button
      mat-button
      [mat-dialog-close]="true"
      cdkFocusInitial
      data-test="ok-button"
    >
      OK
    </button>
  </mat-dialog-actions>
</div>
